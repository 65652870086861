<template>
  <v-card tile flat>
    <loading-bar :value="loading"></loading-bar>
    <v-container fluid>
      <v-row dense justify="space-between">
          <span class="title">Json Props</span>
          <v-spacer></v-spacer>
          <v-btn color="color3" text icon @click.stop="init" v-if="!disabled">
            <v-icon>fas fa-sync-alt</v-icon>
          </v-btn>
      </v-row>
      <v-row dense>
        <v-textarea
          name="input-7-1"
          label="jProps"
          v-model="jprops"
          auto-grow
          :error-messages="valid ? [] : 'Invalid JSON'"
          :disabled="disabled || loading"
          @change="update"
        ></v-textarea>
      </v-row>
      <v-row dense class="justify-end">
        <v-btn color="color3" text icon @click.stop="prettyPrint" v-if="!disabled">
          <v-icon>fas fa-brackets-curly</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <template v-if="dirty && false">
      <v-tooltip top>
        <span>Save</span>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              color="success white--text"
              key="save"
              fab
              bottom
              right
              fixed
              style="margin-right: 120px"
              @click.stop="onSaveClick"
              :disabled="!valid"
              :loading="loading"
            >
              <v-icon>fas fa-save</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-tooltip>
      <v-tooltip top>
        <span>Cancel</span>
        <template v-slot:activator="{ on }">
          <v-fab-transition>
            <v-btn
              v-on="on"
              color="error white--text"
              fab
              bottom
              right
              fixed
              style="margin-right: 60px"
              @click.stop="init"
              :disabled="loading"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
      </v-tooltip>
    </template>
  </v-card>
</template>

<script>
export default {
  props: ['active', 'product', 'disabled'],
  data () {
    return {
      loading: false,
      jprops: null
    }
  },
  computed: {
    valid () {
      try {
        JSON.parse(this.jprops)
        return true
      } catch (e) {
        return false
      }
    },
    dirty () {
      if (!this.valid) return true
      return (JSON.stringify(JSON.parse(this.jprops)) !== JSON.stringify(this.product.jsonProps))
    }
  },
  methods: {
    init () {
      this.jprops = JSON.stringify(this.product.jsonProps, null, 2)
    },
    prettyPrint () {
      this.jprops = JSON.stringify(JSON.parse(this.jprops), null, 2)
    },
    update () {
      if (this.valid) {
        this.product.jsonProps = JSON.parse(this.jprops)
      }
    }
  },
  watch: {
    active: 'init'
  },
  mounted () {
    this.init()
  }
}
</script>
